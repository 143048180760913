<template>
  <div>
    <div class="q-pa-md row">
      <h2 class="text-primary text-h4 col-12">
        ALLGEMEINE GESCHÄFTSBEDINGUNGEN
      </h2>
      <div class="q-pa-md q-mx-auto col-lg-8 col-md-10 col-12">
        <h3 class="text-accent text-h6 col-12">
          (nachfolgend „Geschäftsbedingungen“ genannt)<br />
          TAAVI's TEA s. r. o.<br />
          Identifikationsnummer: 02970813, Umsatzsteuernummer: CZ02970813, Pod
          Všemi svatým 2704/115, 301 00 PLZEŇ<br />
          (nachfolgend „Verkäufer“ genannt)<br />
        </h3>
        <p class="text-accent">
          <strong>1. Einleitende Bestimmungen und Begriffsdefinitionen</strong
          ><br />
          <br />1.1 Diese Geschäftsbedingungen regeln die gegenseitigen Rechte
          und Pflichten des Verkäufers und des Käufers auf der anderen Seite
          (nachfolgend „Käufer“ genannt) beim Verkauf von Produkten. Käufer ist
          die natürliche oder juristische Person, die in der Kopfzeile des
          Kaufvertrags angegeben ist. <br />1.2 Diese Allgemeinen
          Geschäftsbedingungen bilden einen integralen Bestandteil des
          Kaufvertrags, und durch die Unterzeichnung des Kaufvertrags bestätigt
          der Käufer, dass er sich mit diesen Allgemeinen Geschäftsbedingungen
          vertraut gemacht hat und dass er ausdrücklich anerkennt, dass diese
          Allgemeinen Geschäftsbedingungen Teil der vertraglichen Vereinbarung
          sind zwischen ihm und dem Verkäufer. Abweichende Vereinbarungen der
          Teilnehmer gehen, sofern sie schriftlich niedergelegt und von beiden
          Parteien unterzeichnet sind, den Regelungen dieser
          Teilnahmebedingungen vor. <br />1.3 Der Verkäufer weist den Käufer
          ausdrücklich darauf hin, dass die verkauften Produkte für den üblichen
          und normalerweise häufigen Gebrauch bestimmt sind, sofern im
          Kaufvertrag nichts anderes angegeben ist. Die Rechte des Käufers aus
          mangelhafter Leistung bestehen nicht, wenn der Mangel durch übermäßige
          Beanspruchung durch Verwendung des Produkts für seinen nicht üblichen
          und normalerweise häufigen Gebrauch verursacht wurde. <br />1.4 Wenn
          der Käufer ein Verbraucher im Sinne des nachstehenden Punktes 1.7 ist,
          richten sich die nicht durch diese Bedingungen geregelten Beziehungen
          nach dem Gesetz Nr. 89/2012 Slg., Bürgerliches Gesetzbuch in der
          geänderten Fassung (im Folgenden als „Bürgerliches Gesetzbuch“
          bezeichnet). ) und Gesetz Nr. 634/1992 Coll. über den
          Verbraucherschutz in der jeweils geltenden Fassung (im Folgenden
          „Konsumentenschutzgesetz“ genannt). <br />1.5 Ist der Käufer eine
          andere juristische Person als ein Verbraucher im Sinne des
          nachstehenden Punktes 1.8, gelten die nicht durch diese Allgemeinen
          Geschäftsbedingungen des Bürgerlichen Gesetzbuchs geregelten
          Beziehungen (wobei gemäß § 2158 Absatz 1 des Bürgerlichen Gesetzbuchs
          die Bestimmungen der §§ 2158 bis 2174 BGB finden keine Anwendung).
          <br />1.6 Der Verkäufer ist eine natürliche Person, die bei Abschluss
          und Erfüllung des Vertrages im Rahmen ihrer gewerblichen Tätigkeit
          handelt. <br />1.7 Käufer - Verbraucher ist jeder Käufer, es sei denn,
          es handelt sich um einen Käufer - eine andere juristische Person im
          Sinne von Absatz 1.8 dieser Allgemeinen Geschäftsbedingungen.
          <br />1.8 Käufer - Anderer ist ein Käufer, der Unternehmer ist und
          sich bei Abschluss des Vertrages aus den Umständen ergibt, dass der
          Kauf mit seiner gewerblichen Tätigkeit in Zusammenhang steht.
          Unternehmer ist eine Person, die auf eigene Rechnung und Verantwortung
          eine Erwerbstätigkeit in gewerblicher oder ähnlicher Weise mit der
          Absicht ausübt, dies ständig zur Erzielung von Gewinn zu tun. Als
          Unternehmer gilt auch, wer Verträge im Zusammenhang mit seiner eigenen
          Geschäfts-, Produktions- oder ähnlichen Tätigkeit oder in
          selbständiger Ausübung seines Berufes abschließt, oder eine Person,
          die im Auftrag oder im Auftrag eines Unternehmers handelt. <br />1.9
          Gegenstand des Kaufs aufgrund des Kaufvertrags ist das im Kaufvertrag
          bezeichnete Produkt (im Folgenden auch „Produkt“ genannt). Maßgebend
          sind die Daten zum Produkt einschließlich des Kaufpreises zum
          Zeitpunkt des Kaufvertragsabschlusses.<br />

          <br /><strong>2. Bestellung, Kaufvertrag und Produktlieferung</strong
          ><br />
          <br />2.1 Das Vertragsverhältnis zwischen dem Verkäufer und dem Käufer
          kommt durch die Unterzeichnung des Kaufvertrages zustande, der auf der
          Grundlage der Bestellung des Käufers erstellt wird. Der Käufer gibt
          die Bestellung auf: <br />a) per E-Mail an info@badefasser.com
          <br />b) ein Webformular, das auf der Website www.badefasser.com
          verfügbar ist <br />c) persönlich <br />d) telefonisch unter +420 728
          717 961 <br />e)mit unsere partneren auf der Website
          www.badefasser.com <br />Auf der Grundlage der Bestellung erstellt der
          Verkäufer für den Käufer ein Preisangebot, dessen Gültigkeit 7
          Kalendertage ab Ausstellungsdatum beträgt, sofern nicht anders
          angegeben. Die Preise für die im Preisangebot aufgeführten Produkte
          beinhalten keine damit verbundenen Dienstleistungen, sofern nicht
          ausdrücklich etwas anderes angegeben ist. Der Wunsch nach Erbringung
          von damit verbundenen Dienstleistungen muss ausdrücklich in der
          Bestellung angegeben werden. Nach Zustimmung zum Preisangebot sendet
          der Verkäufer dem Käufer eine Anzahlungsrechnung. Nach Eingang der
          Bestellung kann der Verkäufer einen Kaufvertrag aufsetzen und dem
          Käufer zur Bestätigung zusenden. Der Kaufvertrag kommt am Tag der
          Anzahlung zustande. Ein vom Käufer vereinbartes Preisangebot gilt auch
          dann als Kaufvertrag, wenn die Anzahlungsrechnung bezahlt ist. Als
          Unterzeichnung des Kaufvertrages gilt auch die Annahme des
          Preisangebotes. Der Kaufvertrag (bzw. das Preisangebot) muss folgende
          Grundvoraussetzungen enthalten: Identifizierung des Verkäufers,
          Identifizierung des Käufers, genaue Spezifikation des Produkts und
          seiner Menge (ggf. einschließlich zugehöriger Dienstleistungen) und
          des Preises der Produkte. <br />2.2 Vom Verkäufer herausgegebene
          Preislisten sowie mündliche und telefonische Auskünfte über
          Produktpreise sind informativ, für den Verkäufer unverbindlich und für
          den Käufer nicht durchsetzbar. Der Verkäufer behält sich das Recht
          vor, die technischen Parameter zu ändern, oder Produktpreise. Der
          Verkäufer ist nicht verantwortlich für Fehler, die beim Druck
          kommerzieller und technischer Materialien entstehen. <br />2.3 Die
          Lieferfrist für die Lieferung eines Produktes beträgt je nach
          Komplexität und Ausstattung 2-6 Wochen ab Anzahlungsdatum (siehe Punkt
          III dieser AGB). Der Verkäufer ist berechtigt, das Produkt auch vor
          der im Kaufvertrag festgelegten Frist zu liefern, wenn der Käufer
          mindestens 3 Tage vor dem vorzeitigen Liefertermin benachrichtigt
          wird. Für den Fall, dass der Lieferant des Verkäufers mit der
          Lieferung eines Bestandteils des Produkts in Verzug ist, so dass die
          im Kaufvertrag angegebene Lieferfrist überschritten wird, ist der
          Verkäufer berechtigt, einseitig eine andere Lieferfrist festzulegen;
          der Verkäufer ist verpflichtet, den Käufer über diese Tatsache zu
          informieren. Vereinbaren die Vertragsparteien nach Abschluss des
          Kaufvertrages ihre Änderungen schriftlich, so verlängert sich die
          ursprünglich im Vertrag angegebene Lieferzeit angemessen, sofern nicht
          ausdrücklich schriftlich etwas anderes bestimmt ist. <br />2.4 Der
          Käufer ist verpflichtet, die Ware innerhalb von 5 Tagen nach Lieferung
          abzuholen bzw alternative Lieferzeiten. Der Käufer ist verpflichtet,
          das Produkt gemäß dem Kaufvertrag zu übernehmen. Der Käufer ist nur
          berechtigt, das Produkt zu übernehmen, wenn er zuvor den gesamten
          Kaufpreis des Produkts bezahlt hat. Für den Fall, dass der Käufer die
          Ware nicht innerhalb der angegebenen Frist abholt oder dem Verkäufer
          die Auslieferung der Ware nicht gestattet, ist der Verkäufer
          berechtigt, zusätzlich eine Lagergebühr in Höhe von 0,1 % des
          Kaufpreises für jeden Tag der Lagerung zu berechnen wie die Kosten,
          die dem Verkäufer mit der Lagerung entstehen. <br />2.5 Bei Übernahme
          der Ware ist der Käufer verpflichtet, den Original-Kaufvertrag
          vorzulegen. <br />2.6 Produkte können vom Verkäufer transportiert
          werden. Lieferung an den Ort bedeutet die Lieferung des Produkts an
          die im Kaufvertrag angegebene Adresse des Käufers, wenn dieser Ort mit
          einem Pkw mit Anhänger erreichbar ist, ist der Käufer verpflichtet,
          eine befahrbare Zufahrtsstraße bereitzustellen, andernfalls der
          Lieferort letzten zugänglichen Ort, und das Produkt wird dort
          abgeholt. Die Zusammensetzung des Produkts wird vom Käufer selbst
          sichergestellt und er trägt die volle Verantwortung dafür. Die
          Transportgebühr beträgt 0.77 EUR/km und wird in beide Richtungen
          erhoben. Bei Nutzung des Transportmittels des Verkäufers ist die
          Fertigstellung und Inbetriebnahme im Produktpreis enthalten.
          Inbetriebnahme bedeutet die Demonstration des 1. Hochwassers nach dem
          Befüllen der Badefässer. Persönliche Abholung - der Käufer kann seinen
          eigenen Transport organisieren, in diesem Fall behält sich der
          Verkäufer das Recht vor, eine Bearbeitungsgebühr von 25 EUR zu
          erheben. <br />2.7 Im Falle seiner Abwesenheit von der im Kaufvertrag
          angegebenen Adresse zum vereinbarten Liefertermin des Produkts gemäß
          dem vorstehenden Absatz ist der Käufer verpflichtet, dafür zu sorgen,
          dass das Produkt von einer autorisierten Person übernommen wird, die
          anwesend ist Original-Kaufvertrag und Nachweis über die Zahlung des
          Kaufpreises und wer das Produkt vom Verkäufer übernimmt. <br />2.8 Der
          Verkäufer und der Käufer betrachten nur die folgenden Tatsachen als
          eine wesentliche Verletzung des Kaufvertrags, die das Recht der
          Vertragsparteien begründen, einseitig vom Kaufvertrag zurückzutreten:
          <br />a) Der Käufer ist mit der Zahlung des Kaufpreises länger als 10
          Kalendertage ab Fälligkeit des Kaufpreises im Rückstand. <br />b) Der
          Käufer ist mit der Übernahme der Ware mehr als 3 Kalendertage nach dem
          vereinbarten Liefertermin in Verzug. <br />c) Der Verkäufer ist mit
          der Lieferung der Ware mehr als 30 Kalendertage im Rückstand.
          <br />2.9. Im Falle höherer Gewalt ist der Verkäufer nicht
          verpflichtet, eine Bestellung des Käufers anzunehmen oder einen mit
          dem Käufer geschlossenen Vertrag zu erfüllen und haftet nicht für
          dadurch verursachte Schäden. Als höhere Gewalt gelten insbesondere
          Naturkatastrophen; Kriegszustand; Mobilisierung; Aufstand; zivile
          Unruhen; Notstand; gesetzliche Beschränkungen für Import, Export,
          Produktion; Verbot des Geldtransfers; Transport oder Transport
          Komplikationen; Streiks; Sabotage; Arbeitskämpfe; allgemeiner Mangel
          an Rohstoffen oder Arbeitskräften; Einschränkungen der
          Energieversorgung; Pflichtverletzungen des Lieferanten und andere
          Tatsachen, die der Verkäufer nicht vorhersehen konnte, ohne dass er
          verpflichtet ist, deren Einfluss auf die Vertragserfüllung
          nachzuweisen. Der Käufer ist berechtigt, die Bestellung wegen
          verspäteter Lieferung nur nach Vereinbarung und mit Zustimmung des
          Verkäufers zu stornieren.<br />
          <br /><strong>3. Preise und Zahlungsbedingungen</strong><br />
          <br />3.1 Der Preis des Produkts wird im Kaufvertrag verbindlich
          vereinbart. Er bestimmt sich nach der zum Zeitpunkt des
          Kaufvertragsabschlusses aktuellen Preisliste des Verkäufers, sofern
          nichts anderes vereinbart ist (nachfolgend „Kaufpreis“ genannt). Der
          Produkttransport ist nicht im Preis inbegriffen (siehe Punkt 2.6 der
          Allgemeinen Geschäftsbedingungen). <br />3.2 Die Preise haben den
          Charakter von Vertragspreisen und sind inklusive Mehrwertsteuer
          angegeben (der Verkäufer ist der Mehrwertsteuerzahler). <br />3.3 Der
          Käufer ist verpflichtet, dem Verkäufer den Kaufpreis ordnungsgemäß und
          rechtzeitig zu zahlen. Der Käufer ist verpflichtet, innerhalb von 3
          Tagen nach Unterzeichnung des Kaufvertrags eine Anzahlung in Höhe von
          30 % des Kaufpreises zu leisten, mit deren Zahlung der Vertrag wirksam
          wird. Der Käufer ist verpflichtet, eine Nachzahlung von 70 % des
          Kaufpreises bis spätestens 5 Tage vor Lieferung der Ware oder in bar
          bei Übergabe der Ware zu leisten. Die Ware wird erst nach
          vollständiger Zahlung des Kaufpreises an den Käufer übergeben.
          <br />3.4. Leistet der Käufer die Nachzahlung des Kaufpreises nicht
          oder nicht rechtzeitig oder verhindert er sonst die Lieferung der
          Ware, so ist er verpflichtet, dem Verkäufer eine Vertragsstrafe in
          Höhe von 30 % des Kaufpreises zu zahlen. Der Käufer erklärt, dass er
          die so vereinbarte Vertragsstrafe für angemessen hält. <br />3.5 Die
          Gefahr des zufälligen Untergangs und der zufälligen Verschlechterung
          der Ware geht mit der Übergabe der Ware an den Käufer auf den Käufer
          über. <br />3.6 Der Käufer ist verpflichtet, dem Verkäufer die Annahme
          der Ware auf dem Lieferschein zu bestätigen. Zusammen mit der Ware
          übersendet der Verkäufer dem Käufer auch eine Rechnung mit den Angaben
          gemäß den einschlägigen gesetzlichen Bestimmungen. <br />3.7 Das
          Eigentum an der Ware geht erst nach vollständiger Zahlung des
          Kaufpreises und Erfüllung aller finanziellen Verpflichtungen des
          Käufers gegenüber dem Verkäufer, einschließlich Verpflichtungen aus
          anderen zwischen dem Käufer und dem Verkäufer abgeschlossenen
          Verträgen, auf den Käufer über. Gleiches gilt, wenn der Käufer die
          Ware nicht übernimmt, obwohl der Verkäufer ihm die Verfügung über sie
          ermöglicht hat. Unbare Zahlungen gelten erst dann als erfüllt, wenn
          der Betrag auf dem Konto des Verkäufers gutgeschrieben ist und die
          Zahlung ordnungsgemäß identifiziert werden kann, damit die Zahlung
          korrekt identifiziert werden kann. <br />3.8 Im Falle einer
          Rechnungsstellung zwischen dem Verkäufer und dem Käufer vereinbaren
          die Parteien, dass die Rechnungen 14 Tage nach Zustellung der Rechnung
          fällig sind. <br />3.9 Bei Zahlungsverzug von Geldleistungen
          vereinbaren die Parteien eine Vertragsstrafe in Höhe von 0,5 % des
          geschuldeten Betrages für jeden Verzugstag. Die Anwendung der
          Vertragsstrafe lässt das Recht auf Schadensersatz in vollem Umfang
          unberührt.<br />

          <br />
          <strong
            >4. Qualitätsgarantie bei Abnahme, Rechte aus mangelhafter Leistung
            und Reklamationsverfahren</strong
          ><br />
          <br />4.1 Der Verkäufer steht gegenüber dem Käufer-Verbraucher dafür
          ein, dass die Ware bei Erhalt frei von Mängeln ist
          (Beschaffenheitsgarantie nach § 2161 BGB). <br />4.2 Weist die Sache
          nicht die in § 2161 BGB bezeichneten Eigenschaften auf, kann der
          Käufer-Verbraucher auch die Lieferung einer neuen mangelfreien Sache
          verlangen, wenn dies wegen der Art des Mangels nicht unzumutbar ist,
          aber wenn Betrifft der Mangel nur einen Teil der Sache, kann der
          Käufer nur Ersatz des Teils verlangen; ist dies nicht möglich, kann er
          vom Vertrag zurücktreten. Ist dies jedoch aufgrund der Art des Mangels
          unverhältnismäßig, insbesondere wenn der Mangel unverzüglich beseitigt
          werden kann, hat der Käufer das Recht auf kostenlose
          Mängelbeseitigung. Tritt der Käufer-Verbraucher nicht vom Vertrag
          zurück oder macht er von seinem Recht auf Lieferung einer neuen
          mangelfreien Sache, auf Austausch eines Bestandteils oder auf
          Reparatur der Sache keinen Gebrauch, kann er einen angemessenen
          Preisnachlass verlangen. Der Käufer-Verbraucher hat auch dann Anspruch
          auf einen angemessenen Rabatt, wenn der Verkäufer einen neuen Artikel
          nicht ohne Mängel liefern, seinen Teil ersetzen oder den Artikel
          reparieren kann, sowie für den Fall, dass der Verkäufer die Situation
          nicht innerhalb einer angemessenen Frist behebt oder wenn die Abhilfe
          dem Käufer-Verbraucher erhebliche Schwierigkeiten bereiten würde.
          <br />4.3 Zeigt sich ein Mangel innerhalb von sechs Monaten nach
          Übernahme des Produkts durch den Käufer-Verbraucher, wird davon
          ausgegangen, dass das Produkt bereits zum Zeitpunkt der Übernahme
          mangelhaft war. <br />4.4 Rechte aus mangelhafter Leistung stehen dem
          Käufer nicht zu, wenn es sich um Produktmängel handelt, die durch den
          Transport des Produkts durch den Käufer, unsachgemäße Handhabung oder
          Montage durch den Käufer oder unsachgemäße Verwendung verursacht
          wurden. <br />4.5 Der Produktmangel ist nicht der bei natürlichen
          Materialien übliche Farb- oder Strukturunterschied. Maßabweichungen
          innerhalb der üblichen Toleranzen sind ebenfalls kein Mangel. Der
          Verkäufer behält sich geringfügige technische Änderungen an den
          verkauften Produkten vor. Bei Produkten, die zu einem niedrigeren
          Preis verkauft wurden, haftet der Verkäufer nicht für einen Mangel,
          für den ein niedrigerer Preis ausgehandelt wurde. <br />4.6 Der Käufer
          ist verpflichtet, eine Untersuchung der Ware durchzuführen, um etwaige
          Mängel schnellstmöglich nach Gefahrübergang der Ware, möglichst
          unmittelbar nach Ablieferung der Ware, festzustellen. Untersucht der
          Käufer die gelieferte Ware nicht rechtzeitig und mit fachmännischer
          Sorgfalt, kann er keine Mängelansprüche geltend machen, unabhängig
          davon, dass die Ware diese Mängel bereits zum Zeitpunkt des
          Gefahrübergangs auf das Eigentum aufwies. Der Käufer ist verpflichtet,
          die offensichtlichen Mängel der gelieferten Ware bei Übernahme auf dem
          Lieferschein zu vermerken. Mit Ablauf von 10 Tagen ab Lieferung der
          Ware sind Ansprüche auf Haftung für offensichtliche Mängel
          ausgeschlossen. Mit der Unterzeichnung des Lieferscheins bestätigt der
          Käufer, dass er das Produkt in der bestellten Menge, Qualität und
          Beschaffenheit erhalten hat. <br />4.7 Das Mängelrecht besteht nicht
          bei Abnutzung der Sache, die durch bestimmungsgemäße Verwendung,
          unsachgemäße Verwendung oder Behandlung, Witterungseinflüsse,
          unsachgemäße Behandlung, vorsätzliche Beschädigung oder dem Käufer
          schriftlich angezeigte Mängel entstanden ist Kaufvertrag. <br />4.8
          Der Käufer-Verbraucher ist berechtigt, das Recht aus einem Mangel, der
          am Produkt auftritt, innerhalb von vierundzwanzig Monaten nach Erhalt
          geltend zu machen, der Käufer-andere juristische Person innerhalb von
          sechs Monaten nach Erhalt. <br />4.9 Für den Käufer-Andere wird im
          Falle einer mangelhaften Leistung der Vertrag abweichend von den
          einschlägigen Vorschriften des Bürgerlichen Gesetzbuches getroffen.
          Folgendes: Das Auftreten eines behebbaren Mangels oder behebbarer
          Mängel gilt immer als geringfügige Vertragsverletzung und nicht als
          wesentliche Vertragsverletzung, im Falle einer geringfügigen
          Vertragsverletzung hat der Käufer-andere Person das Recht, den Mangel
          zu beseitigen oder eine angemessene Nach Wahl des Verkäufers hat der
          Käufer im Falle einer wesentlichen Vertragsverletzung das Recht, einen
          angemessenen Nachlass vom Kaufpreis zu verlangen oder vom Vertrag
          zurückzutreten. <br />4.10 Rechte aus mangelhafter Leistung werden am
          Sitz des Verkäufers, Pod Všemi svatým 115, 301 00, Plzeň, geltend
          gemacht. <br />4.11 Reklamationsverfahren: Reklamationen müssen vom
          Käufer immer schriftlich unter Vorlage des Originals oder einer Kopie
          des Kaufvertrags oder schriftlich per Brief unter Beifügung des
          Originals oder der Kopie des Kaufvertrags erfolgen. Der Käufer ist
          verpflichtet, dem Servicetechniker des Verkäufers den Zugang zum
          Produkt zu dem von ihm vorgeschlagenen Termin zu gewährleisten. Im
          Falle der Notwendigkeit, das Produkt zu entfernen, ist der Käufer
          verpflichtet, für die Verlagerung des Produkts an einen Ort zu sorgen,
          der normalerweise mit einem Auto mit Anhänger erreichbar ist. Wenn
          diese Bedingung nicht erfüllt ist, behält sich der Verkäufer das Recht
          vor, die Reklamation abzulehnen. Im Falle einer unberechtigten
          Reklamation (sofern der Verkäufer den Mangel nicht zu vertreten hat)
          ist der Käufer verpflichtet, dem Verkäufer die Transportkosten und die
          Kosten für die Prüfung des Vorliegens eines Mangels gemäß der
          aktuellen Preisliste zu erstatten, bevor er die reklamierte Ware
          zurücknimmt Produkt. <br />4.12 Der Verkäufer entscheidet über die
          Reklamation des Käufers – Verbrauchers unverzüglich, in komplexen
          Fällen innerhalb von 3 Werktagen. Diese Frist umfasst nicht die Zeit,
          die für eine fachmännische Beurteilung des Mangels erforderlich ist.
          Die Reklamation, einschließlich der Beseitigung des Mangels, muss
          unverzüglich, spätestens innerhalb von 30 Tagen ab dem Datum der
          Einreichung der Reklamation, bearbeitet werden, es sei denn, der
          Verkäufer und der Käufer-Verbraucher vereinbaren eine längere Frist.
          Der Käufer ist verpflichtet, bei der Bearbeitung der Reklamation eine
          sogenannte wirksame Mitwirkung zu leisten. Insbesondere um die
          Möglichkeit der Überprüfung oder Reparatur des Produkts innerhalb des
          vereinbarten Termins zu ermöglichen oder um das Produkt für den
          Transport vorzubereiten oder es nach der Reparatur zu übernehmen.
          <br />4.13 Die Produkte sind gemäß Gebrauchsanweisung zu handhaben und
          die Betriebs- und Wartungsbedingungen einzuhalten. Eingriffe in das
          Produkt dürfen nur vom Verkäufer oder einer von ihm autorisierten
          Person vorgenommen werden. <br />
          <br /><strong>5. Urheberrecht</strong><br />
          <br />Ohne ausdrückliche Zustimmung des Verkäufers dürfen weder der
          Käufer noch sonst jemand die vom Verkäufer herausgegebenen
          Preislisten, Abbildungen, Fotografien, Anleitungen, Tabellen,
          technischen Daten oder deren Teile zu irgendeinem Zweck kopieren oder
          anderweitig vervielfältigen oder Dritten dies gestatten tun Sie dies.
          Ungenauigkeiten oder Fehler in veröffentlichten Materialien oder auf
          der Website www.badefaser.com begründen keine Ansprüche des Käufers
          gegenüber dem Verkäufer. Dem Käufer ist bekannt, dass er durch den
          Kauf von Produkten, die sich im Geschäftsangebot des Verkäufers
          befinden, keinerlei Nutzungsrechte an eingetragenen Marken,
          Handelsnamen, Firmenlogos etc. des Verkäufers oder der Vertragspartner
          des Verkäufers erwirbt, sofern nicht in a konkreter Vertrag.<br />
          <br />
          <strong>6. Schlussbestimmungen</strong><br />
          <br />6.1 Alle Vertragsbeziehungen unterliegen dem tschechischen
          Recht. <br />6.2 Diese AGB treten am Tag ihrer Veröffentlichung in
          Kraft und sind in der jeweiligen Fassung für alle an diesem Tag und
          später getätigten Bestellungen maßgebend. Der Verkäufer behält sich
          das Recht vor, diese AGB zu ändern. Der neue Wortlaut der Bedingungen
          wird auf der Website des Verkäufers www.badefasser.com veröffentlicht.
          Mit dem Tag ihrer Veröffentlichung wird die Wirksamkeit der bisherigen
          AGB aufgehoben, dies berührt jedoch nicht Kaufverträge, die nach dem
          aktuellen Wortlaut der AGB geschlossen werden (siehe Satz 1 dieses
          Absatzes). <br />6.3 Der Käufer ist nicht berechtigt, seine Forderung
          gegen den Verkäufer aus diesem Vertrag ohne Zustimmung des Verkäufers
          an Dritte abzutreten. <br />6.4 Alle Unterlagen werden an die im
          Kaufvertrag angegebene Adresse der Vertragsparteien versandt. Als Tag
          der Lieferung gilt: <br />a) Datum des Eingangs der Sendung beim
          Empfänger, <br />b) dem Tag, an dem der Empfänger die Annahme der
          Sendung verweigert, <br />c) dem Tag, an dem die Sendung als
          unzustellbar zurückgesandt wurde, weil der Empfänger unter der
          angegebenen Adresse nicht auffindbar ist, <br />d) am zehnten Tag nach
          dem Tag der Aufgabe des Pakets bei der Post, unabhängig davon, ob der
          Adressat von der Aufgabe Kenntnis erlangt hat. <br />6.5 Beziehungen
          und etwaige Streitigkeiten, die sich aus dem Vertrag ergeben, werden
          ausschließlich nach dem Recht der Tschechischen Republik und von den
          zuständigen Gerichten der Tschechischen Republik beigelegt. Der
          Vertragsschluss erfolgt in tschechischer Sprache. Wenn eine
          Übersetzung des Textes für die Bedürfnisse des Käufers erstellt wird,
          gilt, dass im Falle eines Streits über die Auslegung von Begriffen die
          Vertragsauslegung in tschechischer Sprache gilt. Diese Allgemeinen
          Geschäftsbedingungen wurden veröffentlicht und traten in Kraft am
          17.2.2023
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TandC",
};
</script>
<style></style>
