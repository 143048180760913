<template>
  <div class="row full-width">
    <div class="col-md-3 col-12 q-mx-auto">
      <h3 class="text-subtitle2 text-primary">Links</h3>
      <!--terms and conditions link-->
      <q-btn
        flat
        color="accent"
        class="q-ma-lg row footer-button-size"
        size="0.8rem"
        to="/t&c/#top"
        icon="fas fa-signature"
        label="Geschäftsbedingungen"
      />
      <!--gdpr document link-->
      <q-btn
        flat
        color="accent"
        class="q-ma-lg row footer-button-size"
        size="0.8rem"
        to="/gdpr/#top"
        icon="fas fa-file-contract"
        label="GDPR"
      />
      <!--about us link-->
      <q-btn
        flat
        color="accent"
        class="q-ma-lg row footer-button-size"
        size="0.8rem"
        to="/about-us/#top"
        icon="fas fa-info-circle"
        label="Über uns und Badefass"
      />
    </div>
    <q-separator vertical inset />
    <div class="col-md-3 col-12 q-mx-auto">
      <h3 class="text-subtitle2 text-primary">designed by:</h3>
      <p class="text-overline">SD</p>
      <h3 class="text-subtitle2 text-primary">powered by:</h3>
      <p class="text-overline">
        TNTSolutions <br />
        Tomas Votava
      </p>
    </div>
    <q-separator vertical inset />
    <div class="col-md-3 col-12 q-mx-auto">
      <h3 class="text-subtitle2 text-primary">Listed</h3>
      <!--partneres and resellers link-->
      <q-btn
        flat
        color="accent"
        class="q-ma-lg row footer-button-size"
        size="0.8rem"
        to="/contact-us/#top"
        icon="fas fa-handshake"
        label="Unsere Partner"
      />
      <!--references link-->
      <q-btn
        flat
        color="accent"
        class="q-ma-lg row footer-button-size"
        size="0.8rem"
        to="/references/#top"
        icon="fas fa-thumbs-up"
        label="Referenz"
      />
      <!--maintanance link-->
      <q-btn
        flat
        color="accent"
        class="q-ma-lg row footer-button-size"
        size="0.8rem"
        to="/maintanance-care/#top"
        icon="fas fa-question-circle"
        label="Badefass Pflege"
      />
    </div>
  </div>
</template>
<style>
.footer-button-size {
  padding-left: 4rem;
}
</style>

<script>
export default {
  name: "OurFooter",
};
</script>
