<template>
  <div>
    <div class="q-pa-md">
      <h2 class="text-primary text-h4">BADEFASSER - ENTWICKLUNG</h2>
      <!--DEVELOPMENT-->
      <div
        class="row col-12 q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-my-auto">
          <img class="" src="../assets/badefass2.png" />
        </div>
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">WAS IST EIN BADEFASS</h2>
          <!-- What is Bath Barrel?-->
          <p class="text-accent">
            Ein Badefass ist ein Holzfass, das mit Wasser gefüllt wird und zur
            Entspannung geeignet ist. Holz ist ein natürliches und nachhaltiges
            Fertigungsmaterial, nebenbei wird es in unserem Fall auch zur
            Heizung verwendet. Unser Badefass ist nämlich mit Ofen ausgestattet.
            Inkludiert werden auch zwei mobile und bequeme Sitzbänken. Eine
            bunte Vielfalt von nützlichen Zubehör wird nach dem Kundenwunsch
            auch gerne vermittelt.
          </p>
        </div>
      </div>
      <div
        class="row col-12 q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">
            WIE WÄHLE ICH DEN BESTEN BADEFASS FÜR MICH AUS
          </h2>
          <!-- How do I choose the best option possible?-->
          <p class="text-accent">
            Wählen Sie die richtige Größe. Wie viele Personen baden am meisten.
            In der Winterzeit ist es sehr angenehm, wenn sich ein Badefass in
            der Nähe von einem Gebäude befindet. Die Kunden schätzen, wenn sich
            eine Dusche in der Nähe von Badefass befindet. Die Wasserquelle
            sollte sich in der Nähe von Badefass befinden. Die Abgase, die aus
            dem Ofenrohr kommen, sollten Ihre Umgebung nicht stören. In der Nähe
            von einem Dach kann zu Schneeausfallen in das Badefass zustande
            kommen. Ein gutes Fundament gewährleistet die sichere und
            sorgenfreie Verwendung des Badefasses, da ein Badefass fast 3 Tonen
            hat (Badefass 200).
          </p>
        </div>
        <div class="q-my-auto">
          <img class="" src="../assets/badefass3.png" />
        </div>
      </div>
      <div
        class="row col-12 q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-my-auto">
          <img class="" src="../assets/badefass10.png" />
        </div>
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">EDELSTAHL BADEFASSER - VORTEILE</h2>
          <!-- Why Steinless steel?-->
          <p class="text-accent">
            Sie erfüllen die anspruchsvollen Kriterien für Körperentspannung und
            Wellness. Bewährte Konstruktion für sicheren Betrieb und lange
            Nutzungsdauer. Einfach modernes Design Verwendung von wertvollen
            Materialien mit größtmöglichem Verzicht auf Kunststoffe.
          </p>
        </div>
      </div>
      <div
        class="row col-12 q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">
            WO PLATZIERE ICH DEN BADEFASS - UNTERGRUND
          </h2>
          <!-- Ground - How to properly place a Bath Barrel?-->
          <p class="text-accent">
            Ein gutes Fundament gewährleistet die sichere und sorgenfreie
            Verwendung des Badefasses, da ein Badefass fast 3 Tonen hat
            (Badefass 200). Einen beständigeren Untergrund für das Badefass
            erhalten Sie, indem Sie ein Betonfundament gießen. Es wird ein
            Untergrund empfohlen, welches sich für die Größe des Badezuber
            eignet (4 x 4 Meter). Somit ist auch die Bedienung des Badefasses
            angenehmer (Heizen, Reinigung etc.). Der Untergrund muss eben sein
            und den gesamten Boden des Badefasses abstützen muss. Der
            Wasserablauf sollte durch Rohren oder Schlauch ermöglicht warden.
          </p>
        </div>
        <div class="q-my-auto">
          <img class="" src="../assets/badefass7.png" />
        </div>
      </div>
      <div
        class="row col-12 q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-my-auto">
          <img class="" src="../assets/badefass4.png" />
        </div>
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">BADEFASS - PLATZIERUNG</h2>
          <!-- Why steinless steel?-->
          <p class="text-accent">
            Eine gute Überlegung über Platzierung von Badefass wäre von uns
            stark empfohlen. Die Stelle sollte Ihren Vorstellungen entsprechen.
            Bedenklich ist auch, wie viel Aufmerksamkeit das Badefass auffallen
            soll. Im Raum der Verwendung schätzen die Kunden beim Baden einer
            guten Aussicht sehr.
          </p>
        </div>
      </div>
      <div
        class="row col-12 q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">EDELSTAHL BADEFASSER</h2>
          <!-- What is Bath Barrel?-->
          <p class="text-accent">
            Seit 2008 produzieren wir die Badezuber von rostfreiem Stahl. Im
            Raum der Entwicklung und Produktion arbeiten wir immer im Sinne der
            Nachhaltigkeit und Qualität. Hunderte Badefasser mit unserer Marke
            europaweit stellen uns eine riesengroße Belohnung wie auch eine
            Herausforderung dar.
          </p>
        </div>
        <div class="q-my-auto">
          <img class="" src="../assets/badefass8.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurDevelopment",
};
</script>
<style>
img {
  display: block !important;
  object-fit: contain;
  max-width: 333px;
  max-height: 666px;
  width: auto;
  height: auto;
}
</style>
