import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(router)
  .use(VueGtag, {
    config: {
      id: "G-S5NX0T6XBN",
      params: {
        anonymize_ip: true,
      },
    },
  })
  .mount("#app");
