<template>
  <div>
    <div class="q-pa-md">
      <h2 class="text-primary text-h4">REFERENZ</h2>
      <div class="col-12">
        <div class="row justify-evenly q-ma-xl">
          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent">WEIDEN</div>
              <div class="col-12 text-overline text-primary">ZEDER</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_weiden"
                v-model:fullscreen="fullscreen_weiden"
                infinite
              >
                <q-carousel-slide
                  :name="1"
                  img-src="../assets/references/wat1.jpg"
                />
                <q-carousel-slide
                  :name="2"
                  img-src="../assets/references/wat2.jpg"
                />
                <q-carousel-slide
                  :name="3"
                  img-src="../assets/references/wat3.jpg"
                />
                <q-carousel-slide
                  :name="4"
                  img-src="../assets/references/wat4.jpg"
                />
                <q-carousel-slide
                  :name="5"
                  img-src="../assets/references/wat5.jpg"
                />
                <q-carousel-slide
                  :name="6"
                  img-src="../assets/references/wat6.jpg"
                />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_weiden = !fullscreen_weiden"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>

          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent">CHEMNITZ</div>
              <div class="col-12 text-overline text-primary">PALISANDER</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_chemnitz"
                v-model:fullscreen="fullscreen_chemnitz"
                infinite
              >
                <q-carousel-slide
                  :name="1"
                  img-src="../assets/references/roub4.jpg"
                />
                <q-carousel-slide
                  :name="2"
                  img-src="../assets/references/roub2.jpg"
                />
                <q-carousel-slide
                  :name="3"
                  img-src="../assets/references/roub3.jpg"
                />
                <q-carousel-slide
                  :name="5"
                  img-src="../assets/references/roub1.jpg"
                />
                <q-carousel-slide
                  :name="4"
                  img-src="../assets/references/roub5.jpg"
                />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_chemnitz = !fullscreen_chemnitz"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>
          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent text-uppercase">
                Garmisch - Partenkirchen
              </div>
              <div class="col-12 text-overline text-primary">EICHE</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_garmisch"
                v-model:fullscreen="fullscreen_garmisch"
                infinite
              >
                <q-carousel-slide
                  :name="1"
                  img-src="../assets/references/bs1.jpg"
                />
                <q-carousel-slide
                  :name="2"
                  img-src="../assets/references/bs2.jpg"
                />
                <q-carousel-slide
                  :name="3"
                  img-src="../assets/references/bs3.jpg"
                />
                <q-carousel-slide
                  :name="4"
                  img-src="../assets/references/bs4.jpg"
                />
                <q-carousel-slide
                  :name="5"
                  img-src="../assets/references/bs5.jpg"
                />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_garmisch = !fullscreen_garmisch"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>
          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent">CHAM</div>
              <div class="col-12 text-overline text-primary">PALISANDER</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_cham"
                v-model:fullscreen="fullscreen_cham"
                infinite
              >
                <q-carousel-slide
                  :name="1"
                  img-src="../assets/references/cha1.jpg"
                />
                <q-carousel-slide
                  :name="2"
                  img-src="../assets/references/cha2.jpg"
                />
                <q-carousel-slide
                  :name="3"
                  img-src="../assets/references/cha3.jpg"
                />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_cham = !fullscreen_cham"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>
          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent">GLEMPING - BOHEMIA</div>
              <div class="col-12 text-overline text-primary">PINIE</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_glemping"
                v-model:fullscreen="fullscreen_glemping"
                infinite
              >
                <q-carousel-slide
                  :name="1"
                  img-src="../assets/references/glemping1.jpg"
                />
                <q-carousel-slide
                  :name="2"
                  img-src="../assets/references/glemping2.jpg"
                />
                <q-carousel-slide
                  :name="3"
                  img-src="../assets/references/glemping3.jpg"
                />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_glemping = !fullscreen_glemping"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>
          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent">HOF</div>
              <div class="col-12 text-overline text-primary">LARCHE</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_hof"
                v-model:fullscreen="fullscreen_hof"
                infinite
              >
                <q-carousel-slide
                  :name="1"
                  img-src="../assets/references/ora1.jpg"
                />
                <q-carousel-slide
                  :name="2"
                  img-src="../assets/references/ora2.jpg"
                />
                <q-carousel-slide
                  :name="3"
                  img-src="../assets/references/ora3.jpg"
                />
                <q-carousel-slide
                  :name="4"
                  img-src="../assets/references/ora4.jpg"
                />
                <q-carousel-slide
                  :name="5"
                  img-src="../assets/references/ora5.jpg"
                />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_hof = !fullscreen_hof"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>
          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent">REGENSBURG</div>
              <div class="col-12 text-overline text-primary">FARBLOS</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_regensburg"
                v-model:fullscreen="fullscreen_regensburg"
                infinite
              >
                <q-carousel-slide
                  :name="1"
                  img-src="../assets/references/nat1.jpg"
                />
                <q-carousel-slide
                  :name="2"
                  img-src="../assets/references/nat2.jpg"
                />
                <q-carousel-slide
                  :name="3"
                  img-src="../assets/references/nat3.jpg"
                />
                <q-carousel-slide
                  :name="4"
                  img-src="../assets/references/nat4.jpg"
                />
                <q-carousel-slide
                  :name="5"
                  img-src="../assets/references/nat5.jpg"
                />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_regensburg = !fullscreen_regensburg"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>
          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent">WALLERN</div>
              <div class="col-12 text-overline text-primary">MAHAGONY</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_wallern"
                v-model:fullscreen="fullscreen_wallern"
                infinite
              >
                <q-carousel-slide
                  :name="1"
                  img-src="../assets/references/maha1.jpg"
                />
                <q-carousel-slide
                  :name="2"
                  img-src="../assets/references/maha2.jpg"
                />
                <q-carousel-slide
                  :name="3"
                  img-src="../assets/references/maha3.jpg"
                />
                <q-carousel-slide
                  :name="4"
                  img-src="../assets/references/maha4.jpg"
                />
                <q-carousel-slide
                  :name="5"
                  img-src="../assets/references/maha5.jpg"
                />
                <q-carousel-slide
                  :name="6"
                  img-src="../assets/references/maha6.jpg"
                />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_wallern = !fullscreen_wallern"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>
          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent">EGER</div>
              <div class="col-12 text-overline text-primary">NUSSBAUM</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_eger"
                v-model:fullscreen="fullscreen_eger"
                infinite
              >
                <q-carousel-slide
                  :name="1"
                  img-src="../assets/references/wal1.jpg"
                />
                <q-carousel-slide
                  :name="2"
                  img-src="../assets/references/wal2.jpg"
                />
                <q-carousel-slide
                  :name="3"
                  img-src="../assets/references/wal3.jpg"
                />
                <q-carousel-slide
                  :name="4"
                  img-src="../assets/references/wal4.jpg"
                />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_eger = !fullscreen_eger"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>

          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent">GLEMPING - RUZICKA</div>
              <div class="col-12 text-overline text-primary">TEAK</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_ruzicka"
                v-model:fullscreen="fullscreen_ruzicka"
                infinite
              >
                <q-carousel-slide :name="1" img-src="../assets/Ruzicka2.jpg" />
                <q-carousel-slide :name="2" img-src="../assets/Ruzicka1.jpg" />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_ruzicka = !fullscreen_ruzicka"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>
          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent">PLAUEN</div>
              <div class="col-12 text-overline text-primary">EBENHOLZ</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_plauen"
                v-model:fullscreen="fullscreen_plauen"
                infinite
              >
                <q-carousel-slide
                  :name="1"
                  img-src="../assets/references/plat1.jpg"
                />
                <q-carousel-slide
                  :name="2"
                  img-src="../assets/references/plat2.jpg"
                />
                <q-carousel-slide
                  :name="3"
                  img-src="../assets/references/plat3.jpg"
                />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_plauen = !fullscreen_plauen"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>
          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent">TIMBER HOUSE</div>
              <div class="col-12 text-overline text-primary">FARBLOS</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_timber"
                v-model:fullscreen="fullscreen_timber"
                infinite
              >
                <q-carousel-slide
                  :name="1"
                  img-src="../assets/references/timb1.jpg"
                />
                <q-carousel-slide
                  :name="2"
                  img-src="../assets/references/timb2.jpg"
                />
                <q-carousel-slide
                  :name="3"
                  img-src="../assets/references/timb3.jpg"
                />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_timber = !fullscreen_timber"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>
          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent">PALIS - PILSEN</div>
              <div class="col-12 text-overline text-primary">PALISANDER</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_palis"
                v-model:fullscreen="fullscreen_palis"
                infinite
              >
                <q-carousel-slide
                  :name="1"
                  img-src="../assets/references/palis1.png"
                />
                <q-carousel-slide
                  :name="2"
                  img-src="../assets/references/palis2.png"
                />
                <q-carousel-slide
                  :name="3"
                  img-src="../assets/references/palis3.png"
                />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_palis = !fullscreen_palis"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>
          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent">SCHWANDORF</div>
              <div class="col-12 text-overline text-primary">EICHE</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_roucka"
                v-model:fullscreen="fullscreen_roucka"
                infinite
              >
                <q-carousel-slide :name="1" img-src="../assets/PHOTO2.jpg" />
                <q-carousel-slide :name="2" img-src="../assets/PHOTO1.jpg" />
                <q-carousel-slide :name="3" img-src="../assets/PHOTO3.jpg" />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_roucka = !fullscreen_roucka"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>
          <q-card class="my-card q-ma-lg col-lg-3 col-md-5 col-12 bg-dark">
            <q-card-section>
              <div class="text-h6 q-mb-xs text-accent">JENA</div>
              <div class="col-12 text-overline text-primary">LARCH</div>
            </q-card-section>
            <q-responsive
              class="q-mx-auto"
              :ratio="9 / 8"
              style="width: 500px; max-width: 100%"
            >
              <q-carousel
                control-color="white"
                navigation
                navigation-icon="radio_button_unchecked"
                swipeable
                animated
                arrows
                v-model="slide_jena"
                v-model:fullscreen="fullscreen_jena"
                infinite
              >
                <q-carousel-slide
                  :name="1"
                  img-src="../assets/references/smal1.jpg"
                />
                <q-carousel-slide
                  :name="2"
                  img-src="../assets/references/smal2.jpg"
                />
                <q-carousel-slide
                  :name="3"
                  img-src="../assets/references/smal3.jpg"
                />
                <template v-slot:control>
                  <q-carousel-control
                    position="bottom-right"
                    :offset="[22, 22]"
                  >
                    <q-btn
                      push
                      round
                      dense
                      color="primary"
                      text-color="accent"
                      :icon="fullscreen ? 'fullscreen_exit' : 'fullscreen'"
                      @click="fullscreen_jena = !fullscreen_jena"
                    />
                  </q-carousel-control>
                </template>
              </q-carousel>
            </q-responsive>
          </q-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "OurReferences",
  data() {
    return {
      slide_ruzicka: ref(1),
      fullscreen_ruzicka: ref(false),
      slide_cham: ref(1),
      fullscreen_cham: ref(false),
      slide_hof: ref(1),
      fullscreen_hof: ref(false),
      slide_glemping: ref(1),
      fullscreen_glemping: ref(false),
      slide_wallern: ref(2),
      fullscreen_wallern: ref(false),
      slide_garmisch: ref(1),
      fullscreen_garmisch: ref(false),
      slide_roucka: ref(1),
      slide_regensburg: ref(1),
      fullscreen_regensburg: ref(false),
      fullscreen_roucka: ref(false),
      slide_palis: ref(1),
      fullscreen_palis: ref(false),
      slide_timber: ref(1),
      fullscreen_timber: ref(false),
      slide_weiden: ref(1),
      fullscreen_weiden: ref(false),
      slide_jena: ref(1),
      fullscreen_jena: ref(false),
      slide_plauen: ref(1),
      fullscreen_plauen: ref(false),
      slide_eger: ref(1),
      fullscreen_eger: ref(false),
      slide_chemnitz: ref(5),
      fullscreen_chemnitz: ref(false),
    };
  },
};
</script>

<style>
img {
  display: block !important;
  object-fit: contain;
  max-width: 333px;
  max-height: 666px;
  width: auto;
  height: auto;
}
</style>
