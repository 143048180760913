<template>
  <div class="bg-dark text-primary col-12 row full-width">
    <a
      href="/"
      class="q-mx-auto col-lg-3 col-12"
    >
      <img
        class="q-pa-md q-mx-auto"
        src="../assets/badefasser_com_191_161_131.png"
        style="width: 100%"
      />
    </a>
    <q-space />
    <q-checkbox
      dark
      v-model="showMenu"
      checked-icon="fas fa-times"
      unchecked-icon="fas fa-bars"
      class="row q-mx-auto q-pa-lg menu-on-small-display"
    />

    <div
      class="bg-dark justify-end row q-mx-auto menu-on-display"
      v-show="showMenu == true"
    >
      <q-list bordered separator class="row col-12 no-border bg-dark">
        <q-btn-dropdown
          flat
          active
          class="q-pa-md q-ma-md q-mx-auto col-sm-auto col-10"
          label="ÜBER UNS UND BADEFASSER"
          focus
          style="min-width: 111px"
        >
          <q-list
            class="bg-dark text-primary col-sm-auto col-12"
            style="min-width: 111px"
            @click="showMenu = !showMenu"
          >
            <q-item clickable to="/about-us/#top">
              <q-item-section class="q-pa-sm text-uppercase"
                >BADEFASSER - ÜBERSICHT</q-item-section
              >
            </q-item>
            <q-item clickable to="/maintanance-care/#top">
              <q-item-section class="q-pa-sm text-uppercase"
                >BADEFASSER - PFLEGE</q-item-section
              >
            </q-item>
            <q-item clickable to="/development/#top">
              <q-item-section class="q-pa-sm text-uppercase"
                >BADEFASSER - ENTWICKLUNG</q-item-section
              >
            </q-item>
            <q-item clickable to="/info/#top">
              <q-item-section class="q-pa-sm text-uppercase"
                >DAS EMPFEHLEN WIR</q-item-section
              >
            </q-item>
          </q-list>
        </q-btn-dropdown>
        <q-btn
          flat
          class="q-pa-md q-ma-md q-mx-auto col-sm-auto col-10"
          label="REFERENZ"
          to="/references/#top"
          @click="showMenu = !showMenu"
        />
        <q-btn-dropdown
          flat
          class="q-pa-md q-ma-md q-mx-auto col-sm-auto col-10"
          label="PREISLISTE"
          style="min-width: 111px"
        >
          <q-list
            class="bg-dark text-primary"
            style="min-width: 111px"
            @click="showMenu = !showMenu"
          >
            <q-item clickable to="/pricing140/#top">
              <q-item-section class="q-pa-sm text-uppercase"
                >Preisliste Badefass 140</q-item-section
              >
            </q-item>
            <q-item clickable to="/pricing160/#top">
              <q-item-section class="q-pa-sm text-uppercase"
                >Preisliste Badefass 160</q-item-section
              >
            </q-item>
            <q-item clickable to="/pricing180/#top">
              <q-item-section class="q-pa-sm text-uppercase"
                >Preisliste Badefass 180</q-item-section
              >
            </q-item>
            <q-item clickable to="/pricing200/#top">
              <q-item-section class="q-pa-sm text-uppercase"
                >Preisliste Badefass 200</q-item-section
              >
            </q-item>
          </q-list>
        </q-btn-dropdown>

        <q-btn
          class="q-pa-md q-ma-md q-mx-auto text-dark bg-primary col-sm-auto col-10"
          icon="fas fa-paper-plane"
          label="Kontaktieren Sie uns"
          to="/contact-us/#top"
          @click="showMenu = !showMenu"
        />
      </q-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurHeader",
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>
<style>
.menu-on-small-display {
  display: block;
}

@media (min-width: 888px) {
  .menu-on-small-display {
    display: none !important;
  }
  .menu-on-display {
    display: block !important;
  }
}
</style>
