<template>
  <div>
    <div class="q-pa-md row">
      <h2 class="text-primary text-h4 col-12">GDPR</h2>
      <div class="q-pa-md q-mx-auto col-lg-8 col-md-10 col-12">
        <p class="text-accent">
          DATENSCHUTZ-BESTIMMUNGEN<br />
          Wir respektieren die Privatsphäre aller Personen, die unsere Website
          besuchen. Alle über Sie gesammelten Informationen werden verwendet, um
          die von Ihnen angeforderten Dienstleistungen bereitzustellen, Sie über
          neue Produkte und Dienstleistungen zu informieren und unseren
          Kundenservice zu verbessern. Ihre Daten werden in Übereinstimmung mit
          den einschlägigen gesetzlichen Bestimmungen der Europäischen Union und
          Ihres Landes verwaltet. Ihre Informationen werden nicht an Dritte oder
          andere Unternehmen weitergegeben, mit denen wir direkt oder indirekt
          zusammenarbeiten, um Dienstleistungen zu Ihrem Vorteil zu erbringen.
          Es werden keine personenbezogenen Daten über Sie durch die Verwendung
          von Cookies gesammelt, aber durch die Aktivierung von Cookies geben
          Sie Ihre Zustimmung zu TAAVI's TEA s.r.o. – Badewannen mit Speicherung
          von Cookies in Ihrem Browser. Bitte beachten Sie, dass das Verbot von
          Cookies wahrscheinlich die Funktionalität der Website
          www.badefasser.com und Ihre Erfahrung bei der Verwendung dieser
          Cookies beeinträchtigt. Der Zweck dieser Datenschutzerklärung besteht
          darin, die Arten von Informationen zu beschreiben, die wir von Ihnen
          sammeln, wenn Sie unsere Website besuchen, und zu erklären, wie wir
          diese Informationen verwenden. Es ist unsere Pflicht, Ihre Zustimmung
          zu dieser Tatsache einzuholen. Wenn Sie dies nicht wünschen, können
          wir leider keine Ihrer Anfragen über diese Website bearbeiten. Wenn
          Sie damit einverstanden sind, können Sie dies mit dem Verständnis tun,
          dass wir Ihre Daten NUR zur Bearbeitung Ihrer Anfrage verwenden, oder
          Sie können uns die Erlaubnis erteilen, sie für die weitere
          Kontaktaufnahme zu verwenden (immer in Übereinstimmung mit der
          Datenschutzrichtlinie unten).<br />
          DATENSCHUTZ <br />
          Wir schützen Daten in strikter Übereinstimmung mit der Verordnung (EU)
          2016/679 zum Schutz natürlicher Personen bei der Verarbeitung
          personenbezogener Daten („Verordnung“ oder „DSGVO“). Diese Richtlinie
          gilt ab dem 17. Februar 2023. Wir bemühen uns sicherzustellen, dass
          die gespeicherten personenbezogenen Daten korrekt, relevant und in
          einem angemessenen Umfang sind. Wir werden diese Daten auf dem
          neuesten Stand halten und diese Informationen nicht länger als
          unbedingt erforderlich aufbewahren. Wir speichern Daten auf sichere
          Weise, um unbefugten Zugriff durch andere Personen zu verhindern. Sie
          haben das Recht, einzusehen, was wir über Sie gespeichert haben, und
          etwaige Unrichtigkeiten zu korrigieren. Ohne Ihre Zustimmung werden
          wir keine identifizierbaren Informationen über Sie an Dritte
          weitergeben oder verleihen. Wir können allgemeine Informationen über
          den Verkehr auf unserer Website mit unseren Partnern oder anderen
          Parteien teilen, so dass einzelne Besucher niemals identifiziert
          werden können. Unsere Website kann ohne Angabe personenbezogener Daten
          betrachtet werden. Ohne die Übermittlung Ihrer personenbezogenen Daten
          können wir jedoch nicht weiter mit Ihnen kommunizieren. <br />
          WELCHE PERSÖNLICHEN INFORMATIONEN WERDEN ERHOBEN <br />
          1. Wir erfassen Ihre personenbezogenen Daten über das Kontaktformular.
          Diese Informationen können beispielsweise Ihren Namen, Ihre Adresse,
          Ihre E-Mail-Adresse und Ihre Telefonnummer beinhalten. Wir verwenden
          diese Informationen, um: Ihren Besuch angenehmer zu gestalten Ihnen
          wichtige Informationen über Produkte und Dienstleistungen zu senden
          Ihnen gelegentlich E-Mails über Werbeaktionen und Dienstleistungen zu
          senden, von denen wir glauben, dass sie für Sie von Interesse sein
          könnten Wenn Sie sich dafür entscheiden, Informationen von uns oder
          andere Formen von Informationsnachrichten zu erhalten, Sie können
          diese Option wählen, indem Sie das entsprechende Kästchen im
          Kontaktformular ankreuzen. Sie können uns auch jederzeit per E-Mail
          mitteilen, dass Sie keine Informationsnachrichten mehr von uns
          erhalten möchten. Wenn Sie uns erlauben, Ihre personenbezogenen Daten
          für die oben genannten Zwecke zu verwenden, können wir diese
          Informationen gelegentlich an ein Unternehmen oder eine Organisation
          weitergeben, die wir mit der Aufgabe betrauen, E-Mail- oder andere
          Direktmarketing-Kommunikationen in unserem Namen durchzuführen. In
          diesem Fall stellen wir sicher, dass die Dritten, die mit Ihren Daten
          arbeiten, die einschlägigen gesetzlichen Bestimmungen zum Schutz
          personenbezogener Daten einhalten. 2. Grundlegende
          Personenidentifikationsdaten und Adressdaten Personenbezogene Daten
          sind alle Informationen, die sich im Zusammenhang mit der Erbringung
          von Dienstleistungen und dem Verkauf von Waren auf eine natürliche
          Person beziehen. Der Kunde stellt diese Daten durch Abschluss eines
          Kaufvertrages über den Kauf von Waren und durch Unterzeichnung des
          Lieferscheins und des Kaufvertrages zur Verfügung. Zu diesen Daten
          können gehören: Anrede, Vor- und Nachname, Firmenname, ID-Nummer,
          Umsatzsteuer-Identifikationsnummer, Anschrift des ständigen
          Wohnsitzes, Anschrift des Sitzes oder Geschäftssitzes,
          Rechnungsanschrift, Lieferort, E-Mail, Telefonnummer. 3. Daten zu
          gekauften Waren Um Ihnen jederzeit weitere Informationen zu Ihrem
          gekauften Produkt zur Verfügung stellen zu können und damit dessen
          Nutzung angenehmer zu gestalten, speichern wir Informationen über die
          Ware in folgendem Umfang: Art und Spezifikation der gelieferten Ware,
          Menge der gelieferten Ware und deren Preis. <br />
          SONSTIGE INFORMATIONEN, DIE WÄHREND IHRES BESUCHS AUF UNSEREN
          WEBSEITEN ERFASST WERDEN <br />
          Immer wenn Sie unsere Website www.badefasser.com durchsuchen, Seiten
          lesen oder Informationen herunterladen, zeichnen unsere Computer
          automatisch bestimmte Informationen über Ihren Besuch von dem Computer
          auf, den Sie für den Zugriff auf unsere Website verwenden. Diese
          Informationen identifizieren Sie nicht persönlich. Sie teilen uns
          lediglich mit, wie viele Besucher unsere Website besuchen und welche
          Art von Computern sie verwenden. Mit diesen Informationen können wir
          unsere Website für alle unsere Besucher verbessern und ihren Nutzen
          für Sie erhöhen. Die Informationen umfassen Folgendes: a) Datum und
          Uhrzeit Ihres Zugriffs auf unsere Website. b) Wie viele und welche
          Seiten Sie besucht haben und wie lange Sie auf unserer Website waren.
          c) Ihr Internetdomänenname (dies kann Ihr Internetanbieter sein oder
          Ihr Arbeitsplatz) und „IP“-Adresse (dies ist die Nummer, die Ihrem
          Computer zugewiesen wird, wenn Sie sich mit dem Internet verbinden),
          die ermöglicht Ihnen den Zugriff auf unsere Website. d) Der von Ihnen
          verwendete Internetbrowser (z. B. Microsoft Explorer oder Safari) und
          das Betriebssystem Ihres Computers (z. B. Windows 98). f) Die Website,
          von der Sie auf badefasser.com verwiesen wurden (z. B. Google.com,
          wenn Sie uns in einer Suchmaschine gefunden haben).<br />
          SCHUTZ VON E-MAIL-ADRESSEN <br />
          Ihre E-Mail-Adresse wird nur zur Beantwortung Ihrer Fragen und Wünsche
          genutzt, sofern Sie uns nicht die oben beschriebene Einwilligung
          erteilt haben.<br />
          DATENSCHUTZ BEI PIXEL-TAGS <br />
          Wir können „Pixel-Tags“ verwenden, das sind kleine Grafikdateien, die
          es uns ermöglichen, die Nutzung unserer Website zu verfolgen. Ein
          Pixel-Tag kann Informationen wie die Internet Protocol (IP)-Adresse
          des Computers sammeln, der die Seite heruntergeladen hat, auf der das
          Tag erscheint; URL (Uniform Resource Locator) der Seite, auf der das
          Pixel-Tag erscheint; die Zeit, zu der die Seite mit dem Pixel-Tag
          geladen wurde; die Art des Browsers, der das Pixel-Tag übermittelt
          hat; und die Identifikationsnummer jedes Cookies, das zuvor von diesem
          Server auf Ihrem Computer platziert wurde. Wenn wir mit Ihnen über
          HTML-kompatible E-Mail korrespondieren, verwenden wir möglicherweise
          eine Formaterkennungstechnologie, die es Pixel-Tags ermöglicht, uns
          mitzuteilen, ob Sie unsere E-Mail erhalten und geöffnet haben.
          <br />ZWECKE, RECHTSGRUNDLAGE UND ZEITRAUM DER VERARBEITUNG
          PERSONENBEZOGENER DATEN <br />
          Der Umfang der verarbeiteten Daten richtet sich nach dem Zweck der
          Verarbeitung. Wir bewahren Ihre Daten nur so lange wie nötig oder für
          die gesetzlich vorgeschriebene Zeit auf. Personenbezogene Daten werden
          dann gelöscht. Die grundlegenden Fristen für die Verarbeitung
          personenbezogener Daten sind unten aufgeführt. Im Falle des Warenkaufs
          bei TAAVI'S TEA s.r.o. sind wir berechtigt, grundlegende Personen-,
          Identifikations- und Kontaktdaten des Kunden, Daten über die Ware und
          Daten aus der Kommunikation zwischen dem Kunden und dem Unternehmen
          für die Dauer von 2 Jahren ab Ablauf der Gewährleistungsfrist für die
          Ware. Im Falle einer Verhandlung zwischen uns und einem Interessenten
          über den Warenkauf, die nicht durch Abschluss eines Kaufvertrages
          zustande gekommen ist, sind wir berechtigt, Ihre angegebenen
          personenbezogenen Daten für die Dauer von 1 Monat ab der jeweiligen
          Verhandlung zu speichern. Gemäß § 35 des Gesetzes Nr. 235/2004 Slg.
          über die Mehrwertsteuer werden von uns ausgestellte Rechnungen für
          einen Zeitraum von 10 Jahren ab Ausstellung archiviert. Aufgrund der
          Notwendigkeit, einen Rechtsgrund für die Rechnungsstellung zu
          dokumentieren, werden auch Kaufverträge für einen Zeitraum von 10
          Jahren ab dem Datum der Vertragsbeendigung archiviert. <br />DEINE
          RECHTE<br />
          Gegebenenfalls haben Sie nach dem Datenschutzgesetz eine Reihe von
          Rechten, darunter das Recht, der Verarbeitung zu widersprechen, das
          Recht, die über Sie gespeicherten personenbezogenen Daten einzusehen;
          Sie können uns auch bitten, alle notwendigen Änderungen vorzunehmen,
          um sicherzustellen, dass sie korrekt und aktuell sind; Sie können auch
          den Umfang der personenbezogenen Daten einschränken, die wir über Sie
          verarbeiten; die Übermittlung Ihrer personenbezogenen Daten an eine
          andere Organisation beantragen; oder Sie haben das Recht, Ihre
          personenbezogenen Daten zu löschen, d. h. das sogenannte
          „Vergessenwerden“. Wenn Sie bei Bedarf eine Kopie der
          personenbezogenen Daten benötigen, die wir über Sie gespeichert haben,
          kontaktieren Sie uns bitte per E-Mail oder Telefon. Wir erlauben uns,
          dafür eine Gebühr von 25 EUR festzusetzen. Wir weisen Sie auch auf die
          Möglichkeit hin, eine mögliche Beschwerde bei der Aufsichtsbehörde für
          den Schutz personenbezogener Daten einzureichen.<br />
          WEITERE FRAGEN ZUM DATENSCHUTZ? <br />Als Einzelperson haben Sie das
          Recht, darüber informiert zu werden, welche Informationen wir über Sie
          haben, und etwaige Korrekturen vorzunehmen. Sie haben auch das Recht,
          uns aufzufordern, diese Informationen nicht zu verwenden, indem Sie
          Ihre Zustimmung auf dem Registrierungsformular nicht geben. Wenn Sie
          spezifische Fragen haben, auf die Sie in diesem Abschnitt keine
          Antwort gefunden haben, oder wenn Sie wissen möchten, welche
          Informationen wir derzeit über Sie haben, senden Sie Ihre Anfrage an
          die folgende Adresse: info@badefasser.com
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GDPR",
};
</script>
<style></style>
