<template>
  <div>
    <div class="q-pa-md">
      <h2 class="text-primary text-h4">UNSERE GESCHÄFTSPARTNER UND KONTAKT</h2>
      <div class="row col-12">
        <div class="col-lg-6 col-12 q-pa-md">
          <!--Locate our nearest partner-->
          <div class="q-mx-auto col-12">
            <q-tabs
              v-model="tab"
              breakpoint="666px"
              class="text-primary"
              align="justify"
            >
              <q-tab name="germany" label="DEUTSCHLAND - Hutterer" />
              <q-tab name="austria" label="ÖSTERREICH - ZDY gmbg" />
              <q-tab name="czechia" label="CZECHIA - TAAVIsTEA s.r.o." />
            </q-tabs>
            <q-tab-panels class="bg-dark row" v-model="tab" animated swipeable>
              <q-tab-panel name="germany" class="row justify-around">
                <div class="text-h4 q-mb-md text-accent col-12">
                  DEUTSCHLAND - Hutterer
                </div>
                <p1 class="q-px-sm text-accent col-12">phone: +49 9181 4877316</p1>
                <p1 class="q-px-sm text-accent col-12"
                  >email: info@badefasser.com
                </p1>
                <iframe
                  class="q-pa-md expand-map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2603.5114219593097!2d11.451543615688855!3d49.26670577932952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479f6fea5fb5aaef%3A0x56017513b7c48e2d!2sGoldschmidtstra%C3%9Fe%2C%2092318%20Neumarkt%20in%20der%20Oberpfalz%2C%20N%C4%9Bmecko!5e0!3m2!1scs!2scz!4v1682690768132!5m2!1scs!2scz"                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </q-tab-panel>

              <q-tab-panel name="austria" class="row justify-around">
                <div class="text-h4 q-mb-md text-accent col-12">
                  ÖSTERREICH - ZDY gmbg
                </div>
                <p1 class="q-px-sm text-accent col-12">phone: 728 717 961</p1>
                <p1 class="q-px-sm text-accent col-12"
                  >email: info@badefasser.com
                </p1>
                <div>
                  <iframe
                    class="q-pa-md expand-map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17857.050941534708!2d11.408894614541493!3d47.28779079462629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479d6ecfe1f8ca73%3A0x9d201c7d281d9b0d!2sInnsbruck%2C%20Rakousko!5e0!3m2!1scs!2scz!4v1680086659928!5m2!1scs!2scz"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </q-tab-panel>

              <q-tab-panel name="czechia" class="row justify-around">
                <div class="text-h4 q-mb-md text-accent col-12">
                  CZECHIA - TAAVIsTEA s.r.o.
                </div>
                <p1 class="q-px-sm text-accent col-12"
                  >phone: +420 728 717 961</p1
                >
                <p1 class="q-px-sm text-accent col-12"
                  >email: info@badefasser.com
                </p1>
                <div>
                  <iframe
                    class="q-pa-md expand-map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2577.505612353652!2d13.375592815892967!3d49.757745444913795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470af1f32233b9d7%3A0x4784cd21cc98e607!2zUG9kIFbFoWVtaSBzdmF0w71taSAyNzA0LzExNSwgMzAxIDAwIFBsemXFiCAxLVNldmVybsOtIFDFmWVkbcSbc3TDrQ!5e0!3m2!1scs!2scz!4v1679083780231!5m2!1scs!2scz"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </q-tab-panel>
            </q-tab-panels>
          </div>
        </div>
        <div class="col-lg-6 col-12 q-pa-md">
          <q-form
            @submit="onSubmit"
            id="contact-form"
            method="POST"
            action="https://formspree.io/f/xdovwbjn"
          >
            <q-input
              color="primary"
              dark
              v-model="name"
              name="name"
              label="Vorname und Nachname"
              class="q-pa-md bg-dark"
              bg-color="grey-9"
              outlined
              clearable
              ref="nameRef"
              :rules="[(val) => !!val || 'Name is required']"
            ></q-input>
            <q-input
              color="primary"
              dark
              v-model="phone"
              name="phone"
              label="Telefon"
              class="q-pa-md bg-dark"
              bg-color="grey-9"
              outlined
              clearable
            ></q-input>
            <q-input
              color="primary"
              dark
              v-model="email"
              label="Email"
              class="q-pa-md bg-dark"
              bg-color="grey-9"
              type="email"
              outlined
              clearable
              name="email"
              ref="emailRef"
              :rules="[(value) => value.includes('@') || 'Enter valid Email']"
            ></q-input>
            <q-input
              color="primary"
              dark
              v-model="message"
              label="Nachricht"
              class="q-pa-md bg-dark"
              bg-color="grey-9"
              outlined
              clearable
              type="textarea"
              name="message"
              ref="messageRef"
              :rules="[(val) => !!val || 'Message is required']"
            ></q-input>
            <q-btn
              flat
              class="q-pa-md q-ma-md text-primary full-width"
              size="0.8rem"
              icon="fas fa-user-shield"
              label="GDPR"
              to="/gdpr/#top"
            />
            <q-btn
              class="q-pa-md q-ma-md text-dark bg-primary"
              icon="fas fa-paper-plane"
              type="submit"
              label="Schicken"
            />
            <span
              class="row justify-around text-primary"
              id="form-status"
            ></span>
          </q-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "ContactUs",
  data() {
    return {
      name: "",
      phone: "",
      message: "",
      tab: ref("germany"),
      email: "",
    };
  },
  methods: {
    onSubmit(event) {
      var form = document.getElementById("contact-form");
      var status = document.getElementById("form-status");
      var data = new FormData(event.target);
      fetch(event.target.action, {
        method: form.method,
        body: data,
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            status.innerHTML = "Thanks for your submission!";
            form.reset();
          } else {
            response.json().then((data) => {
              if (Object.hasOwn(data, "errors")) {
                status.innerHTML = data["errors"]
                  .map((error) => error["message"])
                  .join(", ");
              } else {
                status.innerHTML =
                  "Oops! There was a problem submitting your form";
              }
            });
          }
        })
        .catch(() => {
          status.innerHTML = "Oops! There was a problem submitting your form";
        });
    },
  },
  setup() {
    const nameRef = ref(null);
    const emailRef = ref(null);
    const messageRef = ref(null);

    return {
      model: ref(""),
      nameRef,
      emailRef,
      messageRef,
    };
  },
};
</script>

<style>
@media (min-width: 888px) {
  .expand-map {
    height: 444px;
    width: 666px;
  }
}
</style>
