<template>
  <div>
    <div>
      <q-carousel
        control-color="primary"
        animated
        height="44rem"
        v-model="slide"
        navigation
        infinite
        autoplay="666ms"
        arrows
        swipeable="true"
        transition-prev="slide-right"
        transition-next="slide-left"
      >
        <q-carousel-slide :name="1" img-src="../assets/carousel-image1.jpg" />
        <q-carousel-slide :name="2" img-src="../assets/carousel-image2.jpg" />
        <q-carousel-slide :name="3" img-src="../assets/carousel-image3.jpg" />
        <q-carousel-slide :name="4" img-src="../assets/carousel-image4.jpg" />
        <q-carousel-slide :name="5" img-src="../assets/carousel-image5.jpg" />
      </q-carousel>
    </div>
    <q-btn
      id="lower-scroll"
      flat
      stack
      class="q-ma-lg"
      color="primary"
      icon="fas fa-chevron-down"
      label="MEHR WISSEN"
      to="#lower-scroll"
    />
    <div
      class="row col-12 q-my-auto inline justify-evenly q-pa-md"
      style="min-height: 88vh"
    >
      <div class="q-my-auto">
        <img class="col-lg-4 col-md-6 col-12" src="../assets/badefass1.png" />
      </div>
      <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
        <h2 class="text-primary text-h4">WAS IST EIN BADEFASSER</h2>
        <p class="text-accent">
          Ein Badefass ist ein Holzfass, das mit Wasser gefüllt wird und zur
          Entspannung geeignet ist. Holz ist ein natürliches und nachhaltiges
          Fertigungsmaterial, nebenbei wird es in unserem Fall auch zur Heizung
          verwendet. Unser Badefass ist nämlich mit Ofen ausgestattet.
          Inkludiert werden auch zwei mobile und bequeme Sitzbänken. Eine bunte
          Vielfalt von nützlichen Zubehör wird nach dem Kundenwunsch auch gerne
          vermittelt.
        </p>
      </div>
    </div>
    <div
      class="row col-12 inline justify-evenly q-pa-md"
      style="min-height: 88vh"
    >
      <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
        <h2 class="text-primary text-h4">EDELSTAHL BADEFASS</h2>
        <p class="text-accent">
          Seit 2008 produzieren wir die Badezuber von rostfreiem Stahl. Im Raum
          der Entwicklung und Produktion arbeiten wir immer im Sinne der
          Nachhaltigkeit und Qualität. Hunderte Badefasser mit unserer Marke
          europaweit stellen uns eine riesengroße Belohnung wie auch eine
          Herausforderung dar.
        </p>
      </div>
      <div class="q-my-auto">
        <img class="col-lg-4 col-md-6 col-12" src="../assets/badefass5.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "LandingPage",
  data() {
    return {
      slide: ref(1),
    };
  },
};
</script>

<style></style>
