<template>
  <div>
    <div class="q-pa-md">
      <h2 class="text-primary text-h4">BADEFASSER - PFLEGE</h2>
      <div
        class="row col-12 q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-my-auto">
          <img class="q-mx-auto" src="../assets/filtration.jpg" />
        </div>
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">FILTERUNG</h2>
          <!-- How to take proper care of bath barrel?-->
          <p class="text-accent">
            Die Verwendung nur chemischer Präparate ohne Filtration ist ein
            kleiner Vorteil in Bezug auf die Verlängerung des
            Wasserwechselintervalls, was in einer größeren Sicherheit
            (Bakterien/Gesundheit) besteht. Zur optimalen Nutzung der Wirkung
            chemischer Präparate empfehlen wir den Einsatz einer Filtration.
            Bakterien: Bei einer Temperatur über 18°C entwickeln sie sich
            schneller als in kaltem Wasser und können uns Badegästen mit der
            Zeit zu einer großen Gefahr werden. Algen: Algen gedeihen sowohl in
            kaltem als auch in warmem Wasser. Noch bevor wir es in seiner
            vertrauten grünen Form sehen, erscheint es zunächst als schleimige,
            matschige Oberfläche, die sich auf den Innenflächen Ihres
            Schwimmbeckens ablagert. Algen können ein Gesundheitsrisiko für
            Badegäste darstellen.
          </p>
        </div>
      </div>
      <div
        class="row col-12 q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">CHEMIE</h2>
          <!-- How to take proper care of bath barrel?-->
          <p class="text-accent">
            Für den Einsatz im Schwimmbad kann ein spezielles chemisches
            Präparat für Whirlpools und Badefasser und Temperaturen über 25 °C
            empfohlen werden. Es ist wichtig, die Anweisungen des Herstellers
            des jeweiligen chemischen Produkts zu befolgen, den pH-Wert des
            Wassers regelmäßig zu messen und zu regulieren und den
            Algenwachstumshemmer nicht zu vergessen.
          </p>
        </div>
        <div class="q-my-auto">
          <img class="" src="../assets/badefass9.png" />
        </div>
      </div>
      <div
        class="row col-12 q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-my-auto">
          <img class="q-mx-auto" src="../assets/badefass7.png" />
        </div>
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">
            3 MÖGLICHKEITEN ZUR WASSERFILTERUNG IM BADEFASS
          </h2>
          <!-- Why Steinless steel?-->
          <p class="text-accent">
            Vorbereitung für den nachträglichen Einbau der Filtration -
            Durchbrüche durch die Wand des Badefasses, an denen Sie später
            jederzeit das Filtergerät anbringen können. Die Durchbrüche durch
            die Wand des Badefasses müssen bei der Fertigung vorgenommen werden
            des Badefasses.<br />
            Separate Filterpakete - Sie können den Filter beliebig platzieren,
            z.B. in einer Gartenstruktur (Pergola, Schuppen, Holzschuppen).
            Lieferumfang: Sandfilterung mit Pumpe, Anschlusspaket für Filterung,
            6 m Verbindungsschlauch (3 m Saugen, 3 m Druck).<br />
            Integrierte Paketfiltration - die Filtration ist in einer
            Designabdeckung untergebracht, wo sie vor Witterungseinflüssen
            geschützt ist. Der Filter kann neben dem Badefass platziert werden,
            der obere Deckel des Filterdeckels kann somit als Ablagefläche
            genutzt werden. Lieferumfang: Sandfiltration mit Pumpe,
            Anschlusspaket für Filtration, Filterabdeckung.
          </p>
        </div>
      </div>
      <div
        class="row col-12 q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">ABDECKUNG DES BADEFASSES</h2>
          <!-- Ground - How to properly place a Bath Barrel?-->
          <p class="text-accent">
            Die richtige Abdeckung der Schwimmtonne ist eine wichtige
            Angelegenheit. Sie verhindern eine Verunreinigung des Wassers durch
            herabfallenden Schmutz, Wasserverschlechterung durch UV-Strahlung.
            Wir empfehlen auch das Abdecken, weil: schnellere
            Warmwasserbereitung langsamere Wasserkühlung Verringerung der
            Wasserverschmutzung durch Trümmerniederschlag
          </p>
        </div>
        <div class="q-my-auto">
          <img class="q-mx-auto" src="../assets/cover2.jpg" />
        </div>
      </div>
      <div
        class="row col-12 q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-my-auto">
          <img
            class="q-mx-auto"
            src="../assets/floating_thermo_insulation.jpg"
          />
        </div>
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">SCHWIMMENDE ISOLIERUNGSABDECKUNG</h2>
          <!-- Why Steinless steel?-->
          <p class="text-accent">
            Die sogenannte die kontaktbelegung der oberfläche, evtl. in
            kombination mit einer deckfolie, hat sich am besten bewährt. Die
            schwimmende Dämmabdeckung arbeitet unabhängig vom Wasserstand
            zuverlässig, reduziert die Wasserverdunstung auf ein Minimum und ist
            in puncto Wärmeverlust am effizientesten. Ein Deckel aus Holz oder
            Kunststoff funktionierte wegen übermäßiger Feuchtigkeitsbildung im
            Raum zwischen der Oberfläche des erhitzten Wassers und dem Deckel
            nicht. Feuchtigkeit in Form von Kondenswasser setzt sich an den
            Wänden von Deckel, Rand und Schutzgitter des Kochers sowie an
            anderen Teilen fest und es bildet sich Schimmel, der die
            Badeumgebung verunreinigt und sehr gesundheitsgefährdend ist.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MaintananceCare",
};
</script>

<style>
img {
  display: block !important;
  object-fit: contain;
  max-width: 333px;
  max-height: 666px;
  width: auto;
  height: auto;
}
</style>
