import { createRouter, createWebHistory } from "vue-router";
import AboutUs from "@/views/AboutUs.vue";
import ContactUs from "@/views/ContactUs.vue";
import OurDevelopment from "@/views/OurDevelopment.vue";
import DetailsInfo from "@/views/DetailsInfo.vue";
import GDPR from "@/views/GDPR.vue";
import TandC from "@/views/TandC.vue";
import LandingPage from "@/views/LandingPage.vue";
import MaintananceCare from "@/views/MaintananceCare.vue";
import Pricing_140 from "@/views/Pricing_140.vue";
import Pricing_160 from "@/views/Pricing_160.vue";
import Pricing_180 from "@/views/Pricing_180.vue";
import Pricing_200 from "@/views/Pricing_200.vue";
import References from "@/views/References.vue";

const routes = [
  {
    path: "/about-us",
    name: "About Us",
    component: AboutUs,
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: ContactUs,
  },
  {
    path: "/development",
    name: "Development",
    component: OurDevelopment,
  },
  {
    path: "/info",
    name: "Info",
    component: DetailsInfo,
  },
  {
    path: "/gdpr",
    name: "GDPR",
    component: GDPR,
  },
  {
    path: "/t&c",
    name: "TandC",
    component: TandC,
  },
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/maintanance-care",
    name: "MaintananceCare",
    component: MaintananceCare,
  },
  {
    path: "/pricing140",
    name: "Pricin140",
    component: Pricing_140,
  },
  {
    path: "/pricing160",
    name: "Pricin160",
    component: Pricing_160,
  },
  {
    path: "/pricing180",
    name: "Pricin180",
    component: Pricing_180,
  },
  {
    path: "/pricing200",
    name: "Pricin200",
    component: Pricing_200,
  },
  {
    path: "/references",
    name: "References",
    component: References,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 0,
        behavior: "smooth",
      };
    }
  },
});

export default router;
