<template>
  <div>
    <div class="q-pa-md">
      <h2 class="text-primary text-h4">BADEFASSER - DAS EMPFEHLEN WIR</h2>
      <!--DEVELOPMENT-->
      <div
        class="row q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-my-auto">
          <img class="" src="../assets/badefass10.png" />
        </div>
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">
            WIE WÄHLE ICH DEN BESTEN BADEFASS FÜR MICH AUS
          </h2>
          <!-- What is Bath Barrel?-->
          <p class="text-accent">
            Wählen Sie die richtige Größe. Wie viele Personen baden am meisten.
            In der Winterzeit ist es sehr angenehm, wenn sich ein Badefass in
            der Nähe von einem Gebäude befindet. Die Kunden schätzen, wenn sich
            eine Dusche in der Nähe von Badefass befindet. Die Wasserquelle
            sollte sich in der Nähe von Badefass befinden. Die Abgase, die aus
            dem Ofenrohr kommen, sollten Ihre Umgebung nicht stören. In der Nähe
            von einem Dach kann zu Schneeausfallen in das Badefass zustande
            kommen. Ein gutes Fundament gewährleistet die sichere und
            sorgenfreie Verwendung des Badefasses, da ein Badefass fast 3 Tonen
            hat (Badefass 200).
          </p>
        </div>
      </div>
      <div class="row q-my-auto inline justify-evenly q-pa-md">
        <div class="col-lg-2 col-md-4 col-12 q-ma-md justify-evenly row">
          <h3 class="text-primary text-h6 full-width">BADEFASS 140</h3>
          <q-markup-table dark class="bg-dark text-h4">
            <tbody>
              <tr>
                <td class="text-left">Personenzahl:</td>
                <td class="text-right">bis 3</td>
              </tr>
              <tr>
                <td class="text-left">Volumen Wasser:</td>
                <td class="text-right">1 m<sup>3</sup></td>
              </tr>
              <tr>
                <td class="text-left">Außendurchmesser:</td>
                <td class="text-right">1 450 mm</td>
              </tr>
              <tr>
                <td class="text-left">Innendurchmesser:</td>
                <td class="text-right">1 310 mm</td>
              </tr>
            </tbody>
          </q-markup-table>
          <q-btn
            flat
            class="q-ma-md full-width"
            color="primary"
            icon-right="fas fa-caret-right"
            label="PREISLISTE BADEFASS 140"
            to="/pricing140/#top"
          />
          <p></p>
        </div>
        <div class="col-lg-2 col-md-4 col-12 q-ma-md justify-evenly row">
          <h3 class="text-primary text-h6 full-width">BADEFASS 160</h3>
          <q-markup-table dark class="bg-dark text-h4">
            <tbody>
              <tr>
                <td class="text-left">Personenzahl:</td>
                <td class="text-right">bis 4</td>
              </tr>
              <tr>
                <td class="text-left">Volumen Wasser:</td>
                <td class="text-right">1,4 m<sup>3</sup></td>
              </tr>
              <tr>
                <td class="text-left">Außendurchmesser:</td>
                <td class="text-right">1 660 mm</td>
              </tr>
              <tr>
                <td class="text-left">Innendurchmesser:</td>
                <td class="text-right">1 520 mm</td>
              </tr>
            </tbody>
          </q-markup-table>
          <q-btn
            flat
            class="q-ma-md full-width"
            color="primary"
            icon-right="fas fa-caret-right"
            label="PREISLISTE BADEFASS 160"
            to="/pricing160/#top"
          />
          <p></p>
        </div>
        <div class="col-lg-2 col-md-4 col-12 q-ma-md justify-evenly row">
          <h3 class="text-primary text-h6 full-width">BADEFASS 180</h3>
          <q-markup-table dark class="bg-dark text-h4">
            <tbody>
              <tr>
                <td class="text-left">Personenzahl:</td>
                <td class="text-right">bis 6</td>
              </tr>
              <tr>
                <td class="text-left">Volumen Wasser:</td>
                <td class="text-right">1,8 m<sup>3</sup></td>
              </tr>
              <tr>
                <td class="text-left">Außendurchmesser:</td>
                <td class="text-right">1 870 mm</td>
              </tr>
              <tr>
                <td class="text-left">Innendurchmesser:</td>
                <td class="text-right">1 730 mm</td>
              </tr>
            </tbody>
          </q-markup-table>
          <q-btn
            flat
            class="q-ma-md full-width"
            color="primary"
            icon-right="fas fa-caret-right"
            label="PREISLISTE BADEFASS 180"
            to="/pricing180/#top"
          />
          <p></p>
        </div>
        <div class="col-lg-2 col-md-4 col-12 q-ma-md justify-evenly row">
          <h3 class="text-primary text-h6 full-width">BADEFASS 200</h3>
          <q-markup-table dark class="bg-dark text-h4">
            <tbody>
              <tr>
                <td class="text-left">Personenzahl:</td>
                <td class="text-right">bis 9</td>
              </tr>
              <tr>
                <td class="text-left">Volumen Wasser:</td>
                <td class="text-right">2,3 m<sup>3</sup></td>
              </tr>
              <tr>
                <td class="text-left">Außendurchmesser:</td>
                <td class="text-right">2 080 mm</td>
              </tr>
              <tr>
                <td class="text-left">Innendurchmesser:</td>
                <td class="text-right">1 940 mm</td>
              </tr>
            </tbody>
          </q-markup-table>
          <q-btn
            flat
            class="q-ma-md full-width"
            color="primary"
            icon-right="fas fa-caret-right"
            label="PREISLISTE BADEFASS 200"
            to="/pricing200/#top"
          />
          <p></p>
        </div>
      </div>
      <div
        class="row q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">AUßENDEKOR - ÜBERSICHT</h2>
          <!-- What is Bath Barrel?-->
          <p class="text-accent">
            Jeder Badefass wird mit bioziden Beschichtungen mit algizider,
            fungizider, antimykotischer Wirkung produziert. Der Holzaußendekor
            wird mit Imprägnierung gegen holzzerstörende Insekten versehen.
            Außenseite des Badezubers wird mit einer Imprägnierung mit UV-Schutz
            produziert. <br />
            Lassen Sie sich in unserer Referenzgalerie inspirieren.
          </p>
        </div>
        <div class="q-my-auto">
          <img class="" src="../assets/badefass1.png" />
        </div>
      </div>
      <div
        class="row q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 94vh"
      >
        <div class="col-lg-2 col-md-4 col-12 q-ma-md">
          <h3 class="text-primary text-h6 full-width q-pt-lg">FARBLOS</h3>
          <p class="text-accent text-subtitle1">
            OSMO - HOLZSCHUTZ ÖL-LASUR - 701
          </p>
          <img class="q-mx-auto" src="../assets/colors/ClearMat.png" />
        </div>
        <div class="col-lg-2 col-md-4 col-12 q-ma-md">
          <h3 class="text-primary text-h6 full-width q-pt-lg">PINIE</h3>
          <p class="text-accent text-subtitle1">
            OSMO - HOLZSCHUTZ ÖL-LASUR - 710
          </p>
          <img class="q-mx-auto" src="../assets/colors/Pine.png" />
        </div>
        <div class="col-lg-2 col-md-4 col-12 q-ma-md">
          <h3 class="text-primary text-h6 full-width q-pt-lg">LÄRCHE</h3>
          <p class="text-accent text-subtitle1">
            OSMO - HOLZSCHUTZ ÖL-LASUR - 702
          </p>
          <img class="q-mx-auto" src="../assets/colors/Larch.png" />
        </div>
        <div class="col-lg-2 col-md-4 col-12 q-ma-md">
          <h3 class="text-primary text-h6 full-width q-pt-lg">ZEDER</h3>
          <p class="text-accent text-subtitle1">
            OSMO - HOLZSCHUTZ ÖL-LASUR - 728
          </p>
          <img class="q-mx-auto" src="../assets/colors/RedCedar.png" />
        </div>
        <div class="col-lg-2 col-md-4 col-12 q-ma-md">
          <h3 class="text-primary text-h6 full-width q-pt-lg">EICHE</h3>
          <p class="text-accent text-subtitle1">
            OSMO - HOLZSCHUTZ ÖL-LASUR - 706
          </p>
          <img class="q-mx-auto" src="../assets/colors/Oak.png" />
        </div>
        <div class="col-lg-2 col-md-4 col-12 q-ma-md">
          <h3 class="text-primary text-h6 full-width q-pt-lg">MAHAGONI</h3>
          <p class="text-accent text-subtitle1">
            OSMO - HOLZSCHUTZ ÖL-LASUR - 703
          </p>
          <img class="q-mx-auto" src="../assets/colors/Mahogony.png" />
        </div>
        <div class="col-lg-2 col-md-4 col-12 q-ma-md">
          <h3 class="text-primary text-h6 full-width q-pt-lg">TEAK</h3>
          <p class="text-accent text-subtitle1">
            OSMO - HOLZSCHUTZ ÖL-LASUR - 708
          </p>
          <img class="q-mx-auto" src="../assets/colors/Teak.png" />
        </div>
        <div class="col-lg-2 col-md-4 col-12 q-ma-md">
          <h3 class="text-primary text-h6 full-width q-pt-lg">NUSSBAUM</h3>
          <p class="text-accent text-subtitle1">
            OSMO - HOLZSCHUTZ ÖL-LASUR - 707
          </p>
          <img class="q-mx-auto" src="../assets/colors/Walnut.png" />
        </div>
        <div class="col-lg-2 col-md-4 col-12 q-ma-md">
          <h3 class="text-primary text-h6 full-width q-pt-lg">PALISANDER</h3>
          <p class="text-accent text-subtitle1">
            OSMO - HOLZSCHUTZ ÖL-LASUR - 727
          </p>
          <img class="q-mx-auto" src="../assets/colors/Rosewood.png" />
        </div>
        <div class="col-lg-2 col-md-4 col-12 q-ma-md">
          <h3 class="text-primary text-h6 full-width q-pt-lg">EBENHOLZ</h3>
          <p class="text-accent text-subtitle1">
            OSMO - HOLZSCHUTZ ÖL-LASUR - 712
          </p>
          <img class="q-mx-auto" src="../assets/colors/Ebony.png" />
        </div>
      </div>
      <div
        class="row q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">
            WO PLATZIERE ICH DEN BADEFASS - UNTERGRUND
          </h2>
          <!-- Ground - How to properly place a Bath Barrel?-->
          <p class="text-accent">
            Ein gutes Fundament gewährleistet die sichere und sorgenfreie
            Verwendung des Badefasses, da ein Badefass fast 3 Tonen hat
            (Badefass 200). Einen beständigeren Untergrund für das Badefass
            erhalten Sie, indem Sie ein Betonfundament gießen. Es wird ein
            Untergrund empfohlen, welches sich für die Größe des Badezuber
            eignet (4 x 4 Meter). Somit ist auch die Bedienung des Badefasses
            angenehmer (Heizen, Reinigung etc.). Der Untergrund muss eben sein
            und den gesamten Boden des Badefasses abstützen muss. Der
            Wasserablauf sollte durch Rohren oder Schlauch ermöglicht warden.
          </p>
        </div>
        <div class="q-my-auto">
          <img class="" src="../assets/badefass2.png" />
        </div>
      </div>
      <div
        class="row q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-my-auto">
          <img class="" src="../assets/badefass8.png" />
        </div>
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">PLATZIERUNG UND UMFELD</h2>
          <!-- Why steinless steel?-->
          <p class="text-accent">
            Unter einem Badefass sollten Freiräume angelegt werden, damit die
            Luft unter dem Badefass zirkulieren kann. Die Freiräume werden durch
            einer integrierten Konstruktion vom Badefass dargestellt.<br />

            Ein Badefass darf auf keinen Fall unter dem Boden platziert werden
            (nicht mal ein kleines Teil davon). Das Badefass muss auf einem
            Untergrund aufgestellt werden, der eben ist und das gilt auch für
            die Montage an der Abhang. In diesem Fall sollte die Grenze vom
            Abhang mindestens 1 Meter vom Badefass entfernt werden.
          </p>
        </div>
      </div>
      <div
        class="row q-my-auto inline justify-evenly q-pa-md"
        style="min-height: 88vh"
      >
        <div class="q-pa-md q-my-auto col-lg-4 col-md-6 col-12">
          <h2 class="text-primary text-h4">UNTERGRUND BEKANNTMACHUNG</h2>
          <!-- What is Bath Barrel?-->
          <p class="text-accent">
            Bekanntmachung: Die Konstruktion des Badefasses wird massiv und
            selbsttragend dargestellt. Für die statische Festigkeit und
            Tragfähigkeit des Badefasses oder ihrer Teile ist ein Absinken oder
            Verkanten der Bodenfläche aus der Waagerechten unzulässig. Der
            Bereich unter dem Badefass muss in einer horizontalen Ebene gemäß
            der Norm liegen. Die Abweichung von der horizontalen Ebene darf
            nicht größer als 6 mm sein und das ist in allen Richtungen zu
            beobachten.
          </p>
        </div>
        <div class="q-my-auto">
          <img class="" src="../assets/badefass5.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetailsInfo",
};
</script>
