<template>
  <div>
    <div class="q-pa-md">
      <h2 class="text-primary text-h4">PREISLISTE EDELSTAHL BADEFASS 200</h2>
      <div class="row col-12">
        <div class="col-lg-8 col-12 q-mx-auto q-pa-md">
          <div class="q-pa-md">
            <q-markup-table dark class="bg-dark text-h4">
              <thead>
                <tr class="text-primary">
                  <th class="text-left text-h4">Ausrüstung / Spezifikation</th>
                  <th class="text-middle">Abkühlfass</th>
                  <th class="text-middle">Spezifikation 1</th>
                  <th class="text-middle">Spezifikation 2</th>
                  <th class="text-middle">Spezifikation 3</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-left">
                    3 integrierten Holzpaneele (Fundament)<br />
                    Boden des Badefasses aus Edelstahl<br />
                    Kunststoffboden<br />
                    Stopper<br />
                    35 mm starke Fichtenaußenwand<br />
                    Dämmschicht der Wand des Badefasses<br />
                    Edelstahlbecken<br />
                    Rostfreie Reifen um das Becken<br />
                    Holzkante<br />
                    Soberflächenbehandlung von Holzteilen<br />
                    Freiplatziebare Edelstahlsitze 2x<br />
                    Farbeffekt des Badefasses – Auswahl <br />
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle fa-3x"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle fa-3x"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle fa-3x"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle fa-3x"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    Der Ofen mit einer Luftklappe aus Edelstahl
                  </td>
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Gusseisernes Ofengitter</td>
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Schornstein aus Edelstahl</td>
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    Schornsteinabdeckung aus Edelstahl<br />
                    (Abnehmbar)
                  </td>
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <!--basic equipment-->
                <tr>
                  <td class="text-left">Holztreppen</td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Ascheschaufel</td>
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Isolationsabdeckung</td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    Vorbereitung für Wasserfilteranlagen
                  </td>
                  <td class="text-middle">94 €</td>
                  <td class="text-middle">94 €</td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Schornsteinabdeckung aus Edelstahl</td>
                  <!--steinless steel chimney cover-->
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle">98 €</td>
                  <td class="text-middle">98 €</td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Thermometer</td>
                  <td class="text-middle">11 €</td>
                  <td class="text-middle">11 €</td>
                  <td class="text-middle">11 €</td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Laubkescher</td>
                  <td class="text-middle">14 €</td>
                  <td class="text-middle">14 €</td>
                  <td class="text-middle">14 €</td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Aktivsauerstoff</td>
                  <!--Chemie-->
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle">29 €</td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    Ofen mit höherem Wirkungsgrad (+20%)
                  </td>
                  <!--fireplace with higher efficiency-->
                  <td class="text-middle">440 €</td>
                  <td class="text-middle">440 €</td>
                  <td class="text-middle">440 €</td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    Isolierung zwischen dem Kunststoffboden <br />
                    und dem Becken (4 Schichten)
                  </td>
                  <!--Alu insulation 4layer -->
                  <td class="text-middle">220 €</td>
                  <td class="text-middle">220 €</td>
                  <td class="text-middle">220 €</td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Ovale Schwimmendisolierabdeckung</td>
                  <!-- floating thermo insulation-->
                  <td class="text-middle">166 €</td>
                  <td class="text-middle">166 €</td>
                  <td class="text-middle">166 €</td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    Elektrische Warmwasserbereitung<br />
                    aus Edelstahl - 6kW
                  </td>
                  <!-- electrical water heating system-->
                  <!-- floating thermo insulation-->
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle">696 €</td>
                  <td class="text-middle">696 €</td>
                </tr>
                <tr>
                  <td class="text-left">Externe Filteranlage – Paket</td>
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">
                    Abgedeckte integrierte Filteranlage – Paket
                  </td>
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle text-accent">
                    <i class="fa fa-times-circle"></i>
                  </td>
                  <td class="text-middle text-primary">
                    <i class="fa fa-check-circle"></i>
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Freiplatziebare Edelstahlsitze</td>
                  <td class="text-middle">110 €</td>
                  <td class="text-middle">110 €</td>
                  <td class="text-middle">110 €</td>
                  <td class="text-middle">110 €</td>
                </tr>
                <tr
                  style="background-color: rgba(222, 222, 222, 0.2) !important"
                >
                  <td class="text-left text-bold">BADEFASS - PREIS</td>
                  <td class="text-middle text-bold">4 220 €</td>
                  <td class="text-middle text-bold">5 550 €</td>
                  <td class="text-middle text-bold">6 260 €</td>
                  <td class="text-middle text-bold">7 470 €</td>
                </tr>
              </tbody>
            </q-markup-table>
          </div>
          <p class="text-accent">
            Preise sind inklusive Mehrwertsteuer aufgeführt
          </p>
          <q-btn
            class="q-pa-md q-ma-md text-dark bg-primary"
            icon="fas fa-paper-plane"
            label="Kontaktieren Sie Uns"
            to="/contact-us/#top"
          />
          <h3 class="text-primary text-h4">Lieferung</h3>
          <p class="text-accent">
            Die Lieferungspreis beträgt 0.77 EUR / 1 Km. Die Ware werden auf die
            vereinbarte Lieferadresse zugestellt. Das Ort muss mit einem PKW mit
            Anhänger erreichbar werden.
          </p>
        </div>
      </div>
    </div>

    <q-btn
      flat
      class="q-pa-md q-ma-md text-primary full-width"
      size="0.8rem"
      icon="fas fa-file-signature"
      label="Geschäftsbedingungen"
      to="/t&c/#top"
    />
  </div>
</template>

<script>
export default {
  name: "Pricing_160",
};
</script>

<style></style>
