<template>
  <q-layout view="hHh ppp fff" class="bg-dark" @scroll="scrolled = true">
    <q-header elevated class="bg-dark justify-evenly">
      <q-toolbar class="q-pa-lg row">
        <OurHeader />
      </q-toolbar>
    </q-header>
    <q-page-container id="top">
      <router-view />
      <q-page-sticky position="bottom-right" :offset="[18, 18]">
        <q-btn
          v-show="scrolled"
          fab
          icon="fas fa-chevron-up"
          color="primary"
          to="#top"
        />
      </q-page-sticky>
    </q-page-container>

    <q-footer elevated class="bg-dark">
      <Footer />
    </q-footer>
  </q-layout>
</template>

<script>
import Footer from "./components/Footer.vue";
import OurHeader from "./components/OurHeader.vue";

export default {
  name: "App",
  components: {
    Footer,
    OurHeader,
  },
  data() {
    return {
      scrolled: false,
    };
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
#app {
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 19px;
}
.back-to-top {
  position: absolute;
  bottom: 0;
  right: 0;
}
img {
  display: block !important;
  object-fit: contain;
  max-width: 333px;
  max-height: 666px;
  width: auto;
  height: auto;
}
</style>
